<template>
  <v-row justify="center">
    <template v-if="$vuetify.breakpoint.smAndUp">
      <v-col
        v-for="key in 3"
        :key="'loaderP-'+key"
        class="card-programa"
      >
        <v-skeleton-loader
          v-bind="options"
          type="article, list-item"
        />
        <v-skeleton-loader
          v-bind="options"
          type="article, list-item"
        />
        <v-skeleton-loader
          v-bind="options"
          type="article, list-item"
        />
      </v-col>
    </template>
    <v-col
      v-else
      cols="12"
      class="px-8"
    >
      <v-skeleton-loader
        v-bind="options"
        type="article, list-item"
      />
      <v-skeleton-loader
        v-bind="options"
        type="article, list-item"
      />
      <v-skeleton-loader
        v-bind="options"
        type="article, list-item"
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'LoaderPrograma',
  data() {
    return {
      options: {
        class: 'mb-4',
        boilerplate: false,
        loading: true,
        elevation: 2,
        trasition: 'ease-in'
      },
    }
  },
}
</script>
